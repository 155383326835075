import theme from "./theme";

const options: any = {
  nativeScrollbars: true,
  theme,
  patch: false,
  warnOnly: true,
};

export default options;
