import { palette } from "./colors";

const typography = {
  fontFamily: 'Inter',
  fontSize: '16px',
  headings: {
    fontFamily: "'GT Walsheim Medium', sans-serif",
    fontWeight: 600
  },
  links: {
    color: palette.brightViolet,
  }
};

export default typography;
