import * as React from 'react';
import { RedocStandalone } from 'redoc';

import options from './config/options';
import './App.css';

function App() {
  return (
    <div className="App">
      <RedocStandalone
        specUrl={"./spec.yaml"}
        options={options}
      />
    </div>
  );
}

export default App;
