import colors from './colors';
import sidebar from './sidebar';
import rightPanel from './rightPanel';
import spacing from './spacing';
import typography from './typography';

const theme = {
  colors,
  sidebar,
  rightPanel,
  spacing,
  typography,
};

export default theme;
