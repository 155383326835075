export const palette = {
  veryDarkBlue: '#140A23',
  moderatelyDarkBlue: '#3C3C9C',
  brightViolet: '#6F3CFD',
  opaqueViolet: '#312a46',
  softViolet: '#876EF3',
  softCyan: '#10D2DD',
  white: '#FFFFFF',
  black: '#000000',
};

const colors = {
  primary: {
    main: palette.black
  },
  success: {
    main: '#00aa13',
  },
  error: {
    main: '#e53935',
  },
  warning: {
    main: '#d4ad03',
    contrastText: palette.white,
  },
  text: {
    primary: '#424242',
    secondary: '#4e566d',
  },
  responses: {
    success: {
      color: ({ colors }: any) => colors.success.main,
    },
    error: {
      color: ({ colors }: any) => colors.error.main,
    },
    redirect: {
      color: '#ffa500',
    },
    info: {
      color: '#87ceeb',
    },
  },
  http: {
    get: '#6bbd5b',
    post: '#248fb2',
    put: '#9b708b',
    options: '#d3ca12',
    patch: '#e09d43',
    delete: '#e27a7a',
    basic: '#999',
    link: '#31bbb6',
    head: '#c167e4',
  },
};

export default colors;
